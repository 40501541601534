import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Display`}</h2>
    <p>{`Adjust the display of an element with `}<inlineCode parentName="p">{`.d-block`}</inlineCode>{`, `}<inlineCode parentName="p">{`.d-none`}</inlineCode>{`, `}<inlineCode parentName="p">{`.d-inline`}</inlineCode>{`, `}<inlineCode parentName="p">{`.d-inline-block`}</inlineCode>{`, `}<inlineCode parentName="p">{`.d-table`}</inlineCode>{`, `}<inlineCode parentName="p">{`.d-table-cell`}</inlineCode>{` utilities.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--display'
    }]} height="140" mdxType="StorybookEmbed" />
    <h3>{`The HTML `}<inlineCode parentName="h3">{`hidden`}</inlineCode>{` attribute`}</h3>
    <p>{`As of `}<a parentName="p" {...{
        "href": "https://github.com/primer/css/releases/v10.10.0"
      }}>{`Primer v10.10.0`}</a>{`, `}<inlineCode parentName="p">{`primer-base`}</inlineCode>{` includes a rule that sets `}<inlineCode parentName="p">{`display: none !important`}</inlineCode>{` for any element with the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/hidden"
      }}><inlineCode parentName="a">{`hidden`}</inlineCode>{` attribute`}</a>{`. You can safely use the `}<inlineCode parentName="p">{`hidden`}</inlineCode>{` attribute with display utilities, but we suggest:`}</p>
    <ol>
      <li parentName="ol">{`Use the `}<inlineCode parentName="li">{`hidden`}</inlineCode>{` attribute (and corresponding JavaScript property) if you're going to programmatically show and hide content.`}</li>
      <li parentName="ol">{`Use `}<inlineCode parentName="li">{`d-none`}</inlineCode>{` and/or its responsive variants (`}<inlineCode parentName="li">{`d-sm-block`}</inlineCode>{`, `}<inlineCode parentName="li">{`d-lg-none`}</inlineCode>{`) to conditionally show content at different screen sizes.`}</li>
    </ol>
    <p>{`Rather than toggling the `}<inlineCode parentName="p">{`d-none`}</inlineCode>{` class in JavaScript, you should toggle the `}<inlineCode parentName="p">{`hidden`}</inlineCode>{` property on an element. This means that you won't have to restore any more specific display utility (`}<inlineCode parentName="p">{`d-inline`}</inlineCode>{` or `}<inlineCode parentName="p">{`d-flex`}</inlineCode>{`, for instance) just to work around the order in which they're listed in the stylesheet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Good:
element.hidden = !visible

// Bad:
element.classList.toggle('d-none', !visible)
element.classList.toggle('d-inline', visible)
`}</code></pre>
    <h3><inlineCode parentName="h3">{`display:table`}</inlineCode>{` wrapping issues`}</h3>
    <p>{`There are known issues with using `}<inlineCode parentName="p">{`display:table`}</inlineCode>{` and wrapping long strings, particularly in Firefox. You may need to use `}<inlineCode parentName="p">{`table-fixed`}</inlineCode>{` on elements with `}<inlineCode parentName="p">{`d-table`}</inlineCode>{` and apply column widths to table cells, which you can do with our `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#column-widths"
      }}>{`column width styles`}</a>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--table'
    }]} height="100" mdxType="StorybookEmbed" />
    <h3>{`Responsive display`}</h3>
    <p>{`A selection of display utilities are able to be applied or changed per `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#breakpoints"
      }}>{`breakpoint`}</a>{`. `}<inlineCode parentName="p">{`.d-block`}</inlineCode>{`, `}<inlineCode parentName="p">{`.d-none`}</inlineCode>{`, `}<inlineCode parentName="p">{`.d-inline`}</inlineCode>{`, and `}<inlineCode parentName="p">{`.d-inline-block`}</inlineCode>{` are available as responsive utilities using the following formula: `}<inlineCode parentName="p">{`d-[breakpoint]-[property]`}</inlineCode>{`. For example: `}<inlineCode parentName="p">{`d-md-inline-block`}</inlineCode>{`. Each responsive display utility is applied to the specified breakpoint and up.`}</p>
    <p>{`In the following example, the `}<inlineCode parentName="p">{`ul`}</inlineCode>{` element switches from `}<inlineCode parentName="p">{`display: block`}</inlineCode>{` on mobile to `}<inlineCode parentName="p">{`display: inline-block`}</inlineCode>{` at the `}<inlineCode parentName="p">{`md`}</inlineCode>{` breakpoint, while the list items remain inline.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--responsive'
    }]} height="100" mdxType="StorybookEmbed" />
    <h3>{`Responsive hide`}</h3>
    <p>{`Hide utilities are able to be applied or changed per breakpoint using the following formula:`}<br />{` `}<inlineCode parentName="p">{`hide-[breakpoint]`}</inlineCode>{`, for example: `}<inlineCode parentName="p">{`hide-sm`}</inlineCode>{`. Hide utilities act differently from other responsive styles and are applied to each `}<strong parentName="p">{`breakpoint-range only`}</strong>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shorthand`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Range`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`-sm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0—543px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`-md`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`544px—767px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`-lg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`768px—1011px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`-xl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1012px and above`}</td>
        </tr>
      </tbody>
    </table>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--responsive-hide'
    }]} height="100" mdxType="StorybookEmbed" />
    <h3>{`Text direction`}</h3>
    <p><inlineCode parentName="p">{`.direction-ltr`}</inlineCode>{` or `}<inlineCode parentName="p">{`.direction-rtl`}</inlineCode>{` can be used to change the text direction. This is especially helpful when paired with `}<inlineCode parentName="p">{`.d-table`}</inlineCode>{`, `}<inlineCode parentName="p">{`.d-table-cell`}</inlineCode>{`, and `}<inlineCode parentName="p">{`.v-align-middle`}</inlineCode>{` to create equal height, vertically centered, alternating content.`}</p>
    <h2>{`Visibility`}</h2>
    <p>{`Adjust the visibility of an element with `}<inlineCode parentName="p">{`.v-hidden`}</inlineCode>{` and `}<inlineCode parentName="p">{`.v-visible`}</inlineCode>{`.`}</p>
    <h2>{`Overflow`}</h2>
    <p>{`Adjust element overflow with `}<inlineCode parentName="p">{`.overflow-hidden`}</inlineCode>{`, `}<inlineCode parentName="p">{`.overflow-scroll`}</inlineCode>{`, and `}<inlineCode parentName="p">{`.overflow-auto`}</inlineCode>{`, or use `}<inlineCode parentName="p">{`.overflow-visible`}</inlineCode>{` to undo the effects of CSS with overflow issues. `}<inlineCode parentName="p">{`.overflow-hidden`}</inlineCode>{` can also be used to create a new `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/CSS/Block_formatting_context"
      }}>{`block formatting context`}</a>{` or clear floats.`}</p>
    <p>{`Overflow utilities can also target x- and y-axes independently via:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`.overflow-x-auto`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.overflow-x-hidden`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.overflow-x-scroll`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.overflow-x-visible`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.overflow-y-auto`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.overflow-y-hidden`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.overflow-y-scroll`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`.overflow-y-visible`}</inlineCode></li>
    </ul>
    <p>{`Overflow utilities can be applied or changed per `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#breakpoints"
      }}>{`breakpoint`}</a>{`. Each `}<strong parentName="p">{`responsive`}</strong>{` overflow utility is applied to the specified breakpoint and up, using the following formula: `}<inlineCode parentName="p">{`overflow-[breakpoint]-[axis]-[property]`}</inlineCode>{`. For example: `}<inlineCode parentName="p">{`overflow-x-scroll`}</inlineCode>{` or `}<inlineCode parentName="p">{`overflow-md-x-visible`}</inlineCode>{`.`}</p>
    <h2>{`Floats`}</h2>
    <p>{`Using floats are discouraged since `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/flexbox"
      }}>{`flexbox`}</a>{` offers more features and doesn't need to be cleared. If you still need it, you can use `}<inlineCode parentName="p">{`.float-left`}</inlineCode>{` and `}<inlineCode parentName="p">{`.float-right`}</inlineCode>{` to set floats, and `}<inlineCode parentName="p">{`.clearfix`}</inlineCode>{` to clear.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--floats'
    }]} height="100" mdxType="StorybookEmbed" />
    <h3>{`Responsive floats`}</h3>
    <p>{`Float utilities can be applied or changed per `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#breakpoints"
      }}>{`breakpoint`}</a>{`. This can be useful for responsive layouts when you want an element to be full width on mobile but floated at a larger breakpoint.`}</p>
    <p>{`Each responsive float utility is applied to the specified breakpoint and up, using the following formula: `}<inlineCode parentName="p">{`float-[breakpoint]-[property]`}</inlineCode>{`. For example: `}<inlineCode parentName="p">{`float-md-left`}</inlineCode>{`. Remember to use `}<inlineCode parentName="p">{`.clearfix`}</inlineCode>{` to clear.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--floats-responsive'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Alignment`}</h2>
    <p>{`Adjust the alignment of an element with `}<inlineCode parentName="p">{`.v-align-baseline`}</inlineCode>{`, `}<inlineCode parentName="p">{`.v-align-top`}</inlineCode>{`, `}<inlineCode parentName="p">{`.v-align-middle`}</inlineCode>{` or `}<inlineCode parentName="p">{`.v-align-bottom`}</inlineCode>{`. The vertical-align property only applies to inline or table-cell boxes.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--align'
    }]} height="100" mdxType="StorybookEmbed" />
    <p>{`Use `}<inlineCode parentName="p">{`v-align-text-top`}</inlineCode>{` or `}<inlineCode parentName="p">{`v-align-text-bottom`}</inlineCode>{` to adjust the alignment of an element with the top or bottom of the parent element's font.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--align-text'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Width and height`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.width-fit`}</inlineCode>{` to set max-width 100%.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--width-fit'
    }]} height="500" mdxType="StorybookEmbed" />
    <p>{`Use `}<inlineCode parentName="p">{`.width-full`}</inlineCode>{` to set width to 100%.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--width-full'
    }]} height="100" mdxType="StorybookEmbed" />
    <p>{`Use `}<inlineCode parentName="p">{`.width-auto`}</inlineCode>{` to reset width to `}<inlineCode parentName="p">{`auto`}</inlineCode>{` (initial value). Typically used with `}<strong parentName="p">{`responsive variants`}</strong>{`. Resize the window to see the effect in the example below.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--width-auto'
    }]} height="100" mdxType="StorybookEmbed" />
    <p>{`Use `}<inlineCode parentName="p">{`.height-fit`}</inlineCode>{` to set max-height 100%.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--height-fit'
    }]} height="150" mdxType="StorybookEmbed" />
    <p>{`Use `}<inlineCode parentName="p">{`.height-full`}</inlineCode>{` to set height to 100%.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--height-full'
    }]} height="175" mdxType="StorybookEmbed" />
    <h2>{`Position`}</h2>
    <p>{`Position utilities can be used to alter the default document flow. `}<strong parentName="p">{`Be careful when using positioning, it's often unnecessary and commonly misused.`}</strong></p>
    <p>{`The position of an element depends on the content. Use `}<inlineCode parentName="p">{`top-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`right-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`bottom-0`}</inlineCode>{`, and `}<inlineCode parentName="p">{`left-0`}</inlineCode>{` to further specify an elements final position.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position'
    }]} height="150" mdxType="StorybookEmbed" />
    <p>{`Using the `}<strong parentName="p">{`responsive variants`}</strong>{` (e.g. `}<inlineCode parentName="p">{`.right-md-0`}</inlineCode>{`) can be helpful for positioning select menus, dropdowns, popovers etc. when the content gets shuffled around for certain responsive breakpoints. You can also use `}<inlineCode parentName="p">{`auto`}</inlineCode>{` to "reset" a final position for wider breakpoints (e.g. `}<inlineCode parentName="p">{`right-0 right-md-auto`}</inlineCode>{`).`}</p>
    <h3>{`Relative`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.position-relative`}</inlineCode>{` to create a new stacking context.`}</p>
    <p><em parentName="p">{`Note how the other elements are displayed as if "Two" were in its normal position and taking up space.`}</em></p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position-relative'
    }]} height="200" mdxType="StorybookEmbed" />
    <h3>{`Absolute`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.position-absolute`}</inlineCode>{` to take elements out of the normal document flow.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position-absolute'
    }]} height="150" mdxType="StorybookEmbed" />
    <h3>{`Fixed`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.position-fixed`}</inlineCode>{` to position an element relative to the viewport. `}<strong parentName="p">{`Be careful when using fixed positioning. It is tricky to use and can lead to unwanted side effects.`}</strong></p>
    <p><em parentName="p">{`Note: This example is shown in an `}<inlineCode parentName="em">{`<iframe>`}</inlineCode>{` and therefore will not be positioned to the viewport of this page.`}</em></p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position-fixed'
    }]} height="150" mdxType="StorybookEmbed" />
    <p>{`To fill an entire width or height, use opposing directions.`}</p>
    <p><em parentName="p">{`Note: fixed positioning has been disabled here for demonstration only.`}</em></p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position-fixed-filled'
    }]} height="100" mdxType="StorybookEmbed" />
    <h3>{`Sticky`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`.position-sticky`}</inlineCode>{` to keep an element stuck to an edge as long as its parent is visible. Things to keep in mind:`}</p>
    <ul>
      <li parentName="ul">{`Using the `}<inlineCode parentName="li">{`.position-sticky`}</inlineCode>{` class by itself doesn't have any effect. An additional `}<inlineCode parentName="li">{`[top|bottom|left|right]-0`}</inlineCode>{` class is needed. See the examples below.`}</li>
      <li parentName="ul">{`Add a background color to sticky elements so it covers the content underneath.`}</li>
      <li parentName="ul">{`Use `}<inlineCode parentName="li">{`style="z-index: 1;"`}</inlineCode>{` (or higher) in case there are other elements with `}<inlineCode parentName="li">{`z-index`}</inlineCode>{`.`}</li>
    </ul>
    <h4>{`Top`}</h4>
    <p>{`Combine `}<inlineCode parentName="p">{`.position-sticky`}</inlineCode>{` with `}<inlineCode parentName="p">{`.top-0`}</inlineCode>{` to keep an element stuck to the top.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position-sticky-top'
    }]} height="250" mdxType="StorybookEmbed" />
    <h4>{`Bottom`}</h4>
    <p>{`Combine `}<inlineCode parentName="p">{`.position-sticky`}</inlineCode>{` with `}<inlineCode parentName="p">{`.bottom-0`}</inlineCode>{` to keep an element stuck to the bottom. Can be used as a footer or toolbar.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position-sticky-bottom'
    }]} height="250" mdxType="StorybookEmbed" />
    <h4>{`Left and right`}</h4>
    <p>{`Combine `}<inlineCode parentName="p">{`.position-sticky`}</inlineCode>{` with `}<inlineCode parentName="p">{`.left-0`}</inlineCode>{` or `}<inlineCode parentName="p">{`.right-0`}</inlineCode>{` to keep elements stuck to the left or right when scrolling horizontally.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position-sticky-left-right'
    }]} height="110" mdxType="StorybookEmbed" />
    <h3>{`Responsive position`}</h3>
    <p>{`Position utilities can be applied or changed per breakpoint in responsive layouts. Each responsive position utility is applied to the specified breakpoint and up, using the following formula: `}<inlineCode parentName="p">{`position-[breakpoint]-[property]`}</inlineCode>{`. For example: `}<inlineCode parentName="p">{`position-md-absolute`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--position-responsive'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Screen reader only`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.sr-only`}</inlineCode>{` to position an element outside of the viewport for screen reader access only. `}<strong parentName="p">{`Even though the element can't be seen, make sure it still has a sensible tab order.`}</strong></p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--screen-reader-only'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Show on focus`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.show-on-focus`}</inlineCode>{` to visually hide an element and only show it when focused. This utility can be used to provide additional functionality for keyboard users.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--show-on-focus'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`The media object`}</h2>
    <p>{`Create a media object with utilities.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--media-object'
    }]} height="150" mdxType="StorybookEmbed" />
    <p>{`Create a double-sided media object for a container with a flexible center.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-layout--media-object-double'
    }]} height="150" mdxType="StorybookEmbed" />
    <p>{`You can also create a media object with `}<a parentName="p" {...{
        "href": "./flexbox#media-object"
      }}>{`flexbox utilities`}</a>{` instead of floats which can be useful for changing the vertical alignment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      